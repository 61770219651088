@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(https://fonts.googleapis.com/css?family=Red+Hat+Display:regular,500,600,700,800,900);

@layer components {
  button {
    @apply cursor-pointer bg-transparent text-lg font-medium;
  }

  a {
    @apply cursor-pointer text-inherit no-underline;
  }

  textarea {
    @apply resize-none bg-transparent outline-none;
  }

  input {
    @apply w-full min-w-0 border-none bg-transparent outline-none;
  }

  h1 {
    @apply text-xl font-medium md:text-2xl lg:text-3xl;
  }

  h2 {
    @apply text-lg font-medium md:text-xl lg:text-2xl;
  }

  h3 {
    @apply text-base font-medium md:text-lg lg:text-xl;
  }

  h4 {
    @apply text-sm font-medium md:text-base lg:text-lg;
  }

  /* h5 {
    @apply font-medium text-base md:text-base lg:text-lg;
  } */

  h6 {
    @apply text-base font-medium;
  }
}

@layer base {
  :root {
    --main: #3DC96D;
    --mainHover: #2C5338;
    --secondary: #36313a;
    --secondaryHover: #3d2b4b;
    --bodyBg: linear-gradient(180deg, #efefef 0%, #f0f0f8 100%);
    --textColor: #000;
    --destructive: #ff5742;
    --destructiveHover: #af0700;
    --yellow: #feda32;
    --active: #1c7610;
    --grayText: #a2a2a2;
    --shadow: 0 10px 30px 0 #d8d8e6;
    --shadow2: 1px 1px 2px 0 rgba(117, 117, 117, 0.15),
      -1px -1px 2px 0 rgba(117, 117, 117, 0.15);
    --tableBg: #fff;
    --menuBg: #efefef;
    --gray: #d4d4d4;
    --white: #fff;
    --whiteBlack: #fff;
    --whiteGray: #fff;
    --blackWhite: #000;
    --black: #000;
    --border: #d4d4d4;
    --list: #f6f6f6;
    --bodyBgFrom: #efefef;
    --bodyBgTo: #f0f0f8;
    --logo: #5d5d5d;
    --logoAccent: #8e8e8e;
    --shadowColor: #d8d8e6;
    --selected: #f2f9ee;
    --borderList: #ebebeb;
    --fromPaywall: #252525;
    --paywallTo: #27998a;
  }

  .dark {
    --main: #3DC96D;
    --secondary: #fff;
    --secondaryHover: #efefef;
    --bodyBg: linear-gradient(180deg, #2c2d31 0%, #39353c 100%);
    --textColor: #fff;
    --shadow: 0 10px 30px 0 #1d1d1f;
    --tableBg: #35363a;
    --menuBg: #2c2d31;
    --gray: #5e5e5e;
    --whiteBlack: #000;
    --whiteGray: #232323;
    --blackWhite: #fff;
    --border: #494b51;
    --list: #35363a;
    --bodyBgFrom: #2c2d31;
    --bodyBgTo: #39353c;
    --logo: #8e8e8e;
    --logoAccent: #5d5d5d;
    --shadowColor: #1d1d1f;
    --selected: #494b51;
    --borderList: #494b51;
  }
}

::-webkit-input-placeholder {
  font-family: inherit;
}

::-webkit-scrollbar {
  width: 0;
}

.chrome-picker {
  box-shadow: none !important;
  background-color: transparent !important;

  .flexbox-fix+.flexbox-fix div:last-child {
    display: none !important;
  }

  &>div+div {
    padding: 10px 0 !important;

    .flexbox-fix+.flexbox-fix {
      display: none !important;
    }
  }
}

textarea::placeholder {
  color: var(--placeholder-color);
}